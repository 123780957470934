import * as React from 'react';
import {graphql} from 'gatsby';
import Layout from '../components/Layout';
import SimplePageSection from "../components/SimplePageSection";

const PrivacyPolicy = ({data}) => {
    const {contentfulBasicPageSection} = data;
    return (
        <>
            <Layout>
                <main>
                    <SimplePageSection simplePage={contentfulBasicPageSection}/>
                </main>
            </Layout>
        </>
    )
}

export default PrivacyPolicy;

export function Head({data}) {
    const {contentfulBasicPageSection} = data;
    return (
        <title>{contentfulBasicPageSection.title} | Derschlag</title>
    )
}

export const query = graphql`
    query ($locale: String!) {
        contentfulBasicPageSection(
            contentful_id: {eq: "4JkUSs1fByRFJQwI4y2Yz"}
            node_locale: {eq: $locale}
        ) {
            ... BasicPageFields
        }
    }
`;
